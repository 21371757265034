(async function() {
    $('#modalTrocarEmpresa').on('show.bs.modal', async function() {
        const req  = await fetch('/usuario/getOrganizacoesGrupoAjax');
        const organizacoes = await req.json();
        const divEmpresasDoGrupo = document.querySelector('#empresasDoGrupo');
        divEmpresasDoGrupo.innerHTML = '';
        for(let organizacao of organizacoes) {
            const divCol = document.createElement('div');
            const divCard = document.createElement('div');
            const divCardBody = document.createElement('div');
            const h6 = document.createElement('h6');
            const p = document.createElement('p');
            const input = document.createElement('input');

            divCol.classList.add('col-6');
            divCard.classList.add('card');
            divCard.classList.add('mx-2');
            divCardBody.classList.add('card-body');
            h6.classList.add('font-weight-bold');
            input.classList.add('btn');
            input.classList.add('btn-primary');
            input.classList.add('empresaButton');
            input.type = 'button';
            input.value = 'Entrar';
            input.style.width ='100%';

            h6.innerHTML = organizacao.nome;
            p.innerHTML = organizacao.cpf_cnpj ?? '--';
            input.setAttribute('data-id', organizacao.id);

            divCardBody.appendChild(h6);
            divCardBody.appendChild(p);
            divCardBody.appendChild(input);

            divCard.appendChild(divCardBody);

            divCol.appendChild(divCard);

            divEmpresasDoGrupo.appendChild(divCol);
        }

        const empresaButtons = document.getElementsByClassName("empresaButton");

        for (let i=0; i < empresaButtons.length; i++) {
            empresaButtons[i].addEventListener("click", function(e){
                const empresaId = e.target.getAttribute('data-id');
                const formEmpresa = document.querySelector('#formEmpresa');

                formEmpresa.action = '/usuario/alterar-empresa/'+empresaId;
                formEmpresa.submit();
            });
        }
    });
})();
